import React from "react"
import { Col, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { Layout } from "../components"

export default function About() {
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "about",
        }}
      />
      <Row className="content-container about-image">
        <Col xs="12">
          <div className="about-info info">
            <div className="site-header">
              <h2>O kancelarii</h2>
            </div>
            <hr className="line"></hr>
            <div className="about-introduction">
              <h3>
                Radca prawny Radosław Rybak ukończył Stacjonarne Studia Prawa na
                Wydziale Prawa, Administracji i Ekonomii Uniwersytetu
                Wrocławskiego, a następnie zdał państwowy egzamin zawodowy i
                uzyskał tytuł radcy prawnego.
              </h3>
            </div>
          </div>
        </Col>
        <Col className="about-description" lg="6">
          <p>
            Pierwsze doświadczenie zawodowe zdobywałem jeszcze podczas studiów,
            odbywając praktyki w największych wrocławskich kancelariach. Po
            uzyskaniu wpisu na listę aplikantów Okręgowej Izby Radców Prawnych
            we Wrocławiu, świadczyłem pomoc prawną w ramach współpracy z
            kancelariami radców prawnych.
          </p>
          <p>
            Posiadam bogate doświadczenie w zakresie procesu cywilnego, w tym w
            szczególności sporów bankowych oraz postępowań w przedmiocie
            podziałów nieruchomości. Brałem również udział w postępowaniach
            dotyczących zamówień publicznych, reprezentując zarówno
            zamawiającego jak i wykonawcę.
          </p>
          <p>
            Poza pracą uwielbiam przeprowadzać eksperymenty kulinarne, poświęcić
            się zajmującej lekturze lub rozrywce cyfrowej.
          </p>
        </Col>
        <Col className="about-photo" lg="4">
          <img src={require("../../static/radoslawrybak.webp").default}></img>
        </Col>
      </Row>
    </Layout>
  )
}
